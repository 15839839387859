import axios from "axios";
import { storageService } from "./storage.service";

const baseUrl = storageService.setApiUrl();

class FileManagement {
  apiUrl = baseUrl;

  async fetchLectureNote(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/load-lecture-note`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async storeLectureNote(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/store-lecture-note`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async deleteLectureNote(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/delete-lecture-note`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async submitAudio(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/upload-lecture-audio`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async deleteLectureNoteAudio(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/delete-lecture-audio`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async fetchPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/load-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  /* Update question topics in bulk */
  async updateBulkQuestionTopic(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/update-bulk-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async fetchSinglePastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/load-single-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async storePastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/store-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async updatePastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/update-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async deletePastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/delete-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async fetchTestPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/load-test-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async fetchSingleTestPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(
      `${baseUrl}/load-single-test-past-question`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
  }

  async storeTestPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/store-test-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async updateTestPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/update-test-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  async deleteTestPastQuestion(postData) {
    const apiToken = await storageService.getToken();
    return await axios.post(`${baseUrl}/delete-test-past-question`, postData, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }
}

const fileMgt = new FileManagement();
export { fileMgt };
