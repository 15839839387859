<template>
  <div class="container">
    <h2 class="label-header" style="margin-bottom: 15px">Past Question</h2>
    <div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
      {{ errorMessage }}
    </div>
    <div v-if="!isEditorOpen">
      <button class="btn btn-primary mb-3 mt-1" @click="toggleQuestionEditor">
        Add Past Questions
      </button>
      <div class="card">
        <div class="card-body" v-if="!isLoading">
          <form class="row g-3" @submit.prevent="onSubmit" novalidate>
            <div class="col-4">
              <label class="form-label">Course name</label>
              <select
                class="form-select"
                v-model="vv.course_id.$model"
                @change="loadTopic"
              >
                <option :value="initialValue">Select Course</option>
                <option
                  v-for="(item, i) in courseList"
                  :key="i"
                  :value="item.course_id"
                >
                  {{ item.course_title }}
                </option>
              </select>
              <span class="error">{{
                vv?.course_id?.$errors[0]?.$message
              }}</span>
            </div>
            <div class="col-4">
              <label class="form-label">Question Year</label>
              <select class="form-select" v-model="vv.year_id.$model">
                <option value="0">All Years</option>
                <option
                  v-for="(item, i) in yearList"
                  :key="i"
                  :value="item.year_id"
                >
                  {{ item.year_name }}
                </option>
              </select>
            </div>
            <div class="col-4">
              <label class="form-label">Topic</label>
              <select class="form-select" v-model="vv.topic_id.$model">
                <option value="0">All Topics</option>
                <option
                  v-for="(item, i) in topicList"
                  :key="i"
                  :value="item.topic_id"
                >
                  {{ item.topic_title }}
                </option>
              </select>
            </div>
            <div class="col-12">
              <button class="btn btn-primary" :disabled="isProcessing">
                <span
                  v-if="isProcessing"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="isProcessing" style="padding-left: 15px !important"
                  >Fetching</span
                >
                <span v-if="!isProcessing">Fecth Questions</span>
              </button>
            </div>
          </form>
        </div>
        <div class="card-body loader" v-if="isLoading">
          <div class="spinner-border"></div>
          <h6 style="margin-top: 15px; font-size: 17px">
            {{ loadingMessage }}
          </h6>
        </div>
      </div>
      <!-- {{ JSON.stringify(questionTopicArray) }} -->

      <div class="card mt-4">
        <div class="card-body" v-if="!isProcessing">
          <div style="display: flex; justify-content: end" class="mb-3">
            <button
              class="btn btn-primary"
              :disabled="isProcessing || !isAnyTopicUpdated"
              @click="updateTopics"
            >
              <span
                v-if="isProcessing"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="isProcessing" style="padding-left: 15px !important"
                >Fetching</span
              >
              <span v-if="!isProcessing"
                >Update Question Topic ({{
                  questionTopicArray.filter(
                    (q) => q.topic_id !== q.initial_topic_id
                  ).length
                }}
                questions)</span
              >
            </button>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Question</th>
                  <th>Course code</th>
                  <th>Year</th>
                  <th style="width: 350px; min-width: 300px">Topic</th>
                  <th width="100px">Action</th>
                </tr>
              </thead>
              <tbody v-if="questionList.length > 0">
                <tr v-for="(item, i) in questionList" :key="i">
                  <th>{{ i + 1 }}</th>
                  <td
                    v-html="$generalHelper.decodeHtmlEntites(item.question)"
                  ></td>
                  <td>{{ getCourseName(item.course_code) }}</td>
                  <td>{{ getYearName(item.year_name) }}</td>
                  <td>
                    <div class="">
                      <label class="form-label">Topic</label>
                      <select
                        class="form-select"
                        v-model="questionTopicArray[i].topic_id"
                      >
                        <option
                          v-for="(item, i) in questionListTopics"
                          :key="i"
                          :value="item.topic_id"
                        >
                          {{ item.topic_title }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td width="100px">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm btn-primary"
                        style="margin-right: 10px"
                        @click="editQuestion(item)"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        style="margin-right: 10px"
                        @click="deleteQuestion(item)"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body loader" v-if="isProcessing">
          <div class="spinner-border"></div>
          <h6 style="margin-top: 15px; font-size: 17px">
            {{ loadingMessage }}
          </h6>
        </div>
      </div>
    </div>
    <div v-if="isEditorOpen && !editMode">
      <button class="btn btn-danger mb-3 mt-1" @click="toggleQuestionEditor">
        Close Editor
      </button>
      <create-past-question
        :yearList="yearList"
        :courseList="courseList"
        @closeForm="toggleQuestionEditor"
      />
    </div>
    <div v-if="isEditorOpen && editMode">
      <button class="btn btn-danger mb-3 mt-1" @click="toggleQuestionEditor">
        Close Editor
      </button>
      <edit-past-question
        :questionId="selectedQuestion"
        :yearList="yearList"
        :courseList="courseList"
        :courseId="questionCourseId"
        @closeForm="closeEditForm"
      />
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { projectService } from "@/services/project.service";
import { fileMgt } from "@/services/fileMgt.service";
import CreatePastQuestion from "@/components/PastQuestion/CreatePastQuestion.vue";
import EditPastQuestion from "@/components/PastQuestion/EditPastQuestion.vue";

export default {
  name: "PastQuestion",
  components: {
    CreatePastQuestion,
    EditPastQuestion,
  },
  setup() {
    const fform = reactive({
      course_id: null,
      topic_id: 0,
      year_id: 0,
    });
    const rules = computed(() => ({
      course_id: { required },
      topic_id: {},
      year_id: {},
    }));
    const vv = useVuelidate(rules, fform);
    return {
      vv,
      fform,
    };
  },
  computed: {
    isAnyTopicUpdated() {
      return (
        this.questionTopicArray.filter(
          (q) => Number(q.topic_id) !== Number(q.initial_topic_id)
        ).length > 0
      );
    },
  },
  data() {
    return {
      role: 0,
      initialValue: null,
      isLoading: true,
      loadingMessage: "Loading Courses",
      isProcessing: false,
      showErrorPageAlert: false,
      errorMessage: "",
      folder_path: "",
      courseList: [],
      topicList: [],
      yearList: [],
      questionListTopics: [],
      questionList: [],
      selectedQuestion: 0,
      questionCourseId: 0,
      editMode: false,
      isEditorOpen: false,
      questionTopicArray: [],
    };
  },
  mounted() {
    this.folder_path = this.$route.params.folder;
    this.loadContent();
  },
  methods: {
    loadContent() {
      projectService
        .fetchProjectFile(`${this.folder_path}/course.json`)
        .then((response) => {
          this.courseList = response.data;
          this.loadYear();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    loadYear() {
      projectService
        .fetchProjectFile(`${this.folder_path}/exam_year.json`)
        .then((response) => {
          this.yearList = response.data;
          this.isLoading = false;
          //await this.loadTopic()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadTopic() {
      projectService
        .fetchTopicFile(`${this.folder_path}/topic.json`, this.fform.course_id)
        .then((response) => {
          this.topicList = [];
          this.fform.topic_id = 0;
          if (parseInt(response.data.message_code) === 200) {
            this.topicList = response.data.topic;
          } else {
            this.showErrorPageAlert = true;
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorPageAlert = true;
          this.errorMessage = "Error Connecting To Server";
        });
    },
    toggleQuestionEditor() {
      this.isEditorOpen = !this.isEditorOpen;
      setTimeout(() => {
        eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
      }, 1000);
    },
    async closeEditForm() {
      this.isEditorOpen = !this.isEditorOpen;
      this.onSubmit();
      setTimeout(() => {
        eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
      }, 500);
    },
    getYearName(year_id) {
      let year_name = "";
      this.yearList.map((m) => {
        if (parseInt(m.year_id) === parseInt(year_id)) {
          year_name = m.year_name;
        }
      });
      return year_name;
    },
    getCourseName(course_id) {
      let course_code = "";
      this.courseList.map((m) => {
        if (parseInt(m.course_id) === parseInt(course_id)) {
          course_code = m.course_code;
        }
      });
      return course_code;
    },
    getTopicTitle(topic_id) {
      let topic_title = "";
      this.topicList.map((m) => {
        if (parseInt(m.topic_id) === parseInt(topic_id)) {
          topic_title = m.topic_title;
        }
      });
      return topic_title;
    },
    async onSubmit() {
      this.showErrorPageAlert = false;
      this.isProcessing = true;
      this.loadingMessage = "Fetching Question.......";
      this.vv.$touch();
      if (this.fform.course_id === null) return;
      const postData = {
        topic_id: this.fform.topic_id,
        course_id: this.fform.course_id,
        year_id: this.fform.year_id,
        folder: this.folder_path,
      };
      fileMgt
        .fetchPastQuestion(postData)
        .then((response) => {
          this.isProcessing = false;
          if (parseInt(response.data.message_code) === 200) {
            this.questionListTopics = response.data.question.topic;
            this.questionList = response.data.question.questionList;
            this.questionTopicArray = response.data.question.questionList.map(
              (item) => {
                return {
                  question_id: item.question_id,
                  topic_id: item.topic_title,
                  initial_topic_id: item.topic_title,
                };
              }
            );
            setTimeout(() => {
              eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
            }, 1000);
          } else {
            this.showErrorPageAlert = true;
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.isProcessing = false;
          this.showErrorPageAlert = true;
          this.errorMessage = "Error Connecting To Server";
        });
    },
    async editQuestion(item) {
      this.selectedQuestion = item.question_id;
      this.questionCourseId = item.course_id;
      setTimeout(() => {
        this.editMode = true;
        this.isEditorOpen = true;
      }, 500);
    },
    async deleteQuestion(item) {
      this.$swal({
        text: `Are you sure to delete this question?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete",
        allowOutsideClick: false,
      }).then(async (responses) => {
        if (responses.isConfirmed) {
          const postData = {
            folder: this.folder_path,
            question_id: item.question_id,
            question_image: item.question_image,
            correction_image: item.correction_image,
          };
          fileMgt
            .deletePastQuestion(postData)
            .then((response) => {
              if (parseInt(response.data.message_code) === 200) {
                this.$swal("Question deleted successsfull");
                this.onSubmit();
              } else {
                this.$swal({
                  text: response.data.message,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
              this.$swal({
                text: "Error Connecting To Server",
                icon: "error",
              });
            });
        }
      });
    },
    async updateTopics() {
      this.isProcessing = true;
      this.loadingMessage = "Updating Question.......";
      const postData = this.questionTopicArray.filter(
        (q) => Number(q.topic_id) !== Number(q.initial_topic_id)
      );
      if (postData.length === 0) return;
      fileMgt
        .updateBulkQuestionTopic({
          folder: this.folder_path,
          topic_question_list: postData,
        })
        .then((response) => {
          if (parseInt(response.data.message_code) === 200) {
            this.$swal("Questions updated");
            this.onSubmit();
          } else {
            this.$swal({
              text: response.data.message,
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.isProcessing = false;
          this.showErrorPageAlert = true;
          this.errorMessage = "Error Connecting To Server";
        });
    },
  },
};
</script>

<style scoped>
.card .card-body.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
