<template>
	<div style="border: 1px solid #09568d;">
		<form v-if="!isLoading" @submit.prevent="onSubmit" novalidate>
			<nav class="navbar sticky-top navbar-light bg-dark">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h6 text-light" style="">{{ vv.question_content.$model.length }} question(s) in total</span>
					<div class="">
						<button type="submit" class="btn btn-primary btn-sm" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Updating......</span>
							<span v-if="!isProcessing">Update Question</span>
						</button>
					</div>
				</div>
			</nav>
			<div class="frame bg-white" style="padding: 2rem 1rem;">
				<div class="card">
					<div class="card-body">
						<div class="row g-3">
							<div class="col-4">
								<label class="form-label">Course name</label>
								<select class="form-select" v-model="vv.course_id.$model" @change="loadTopic">
									<option :value="initialValue">Select Course</option>
									<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ item.course_title }}</option>
								</select>
								<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="col-4">
								<label class="form-label">Question Year</label>
								<select class="form-select" v-model="vv.year_id.$model">
									<option :value="initialValue">All Years</option>
									<option v-for="(item, i) in yearList" :key="i" :value="item.year_id">{{ item.year_name }}</option>
								</select>
								<span class="error">{{ vv?.year_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="col-4">
								<label class="form-label">Topic</label>
								<select class="form-select" v-model="vv.topic_id.$model">
									<option :value="initialValue">All Topics</option>
									<option v-for="(item, i) in topicList" :key="i" :value="item.topic_id">{{ item.topic_title }}</option>
								</select>
								<span class="error">{{ vv?.topic_id?.$errors[0]?.$message }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-2 card" style="margin-top: 20px !important;" v-for="(item, i) in vv.question_content.$model" :key="i">
					<div class="card-header">
						Question {{ i + 1 }}
					</div>
					<div class="card-body">
						<div class="row g-3">
							<div class="col-12 question">
								<label for="">Question</label>
								<ckeditor :editor="editor" v-model="item.question" :config="editorConfig"></ckeditor>
								<span class="error">{{ item?.question?.$errors?.$message }}</span>
							</div>
							<div class="col-12">
								<label>Question type</label>
								<select v-model="item.question_type" class="form-select">
									<option value="0">Objective Question</option>
									<option value="1">Sub Objective Question</option>
								</select>
							</div>
							<div class="col-6 options" v-if="parseInt(item.question_type) === 0">
								<label for="">Option A</label>
								<ckeditor :editor="editor" v-model="item.option_a" :config="optionConfig"></ckeditor>
								<span class="error">{{ item?.option_a?.$errors?.$message }}</span>
							</div>
							<div class="col-6 options" v-if="parseInt(item.question_type) === 0">
								<label for="">Option B</label>
								<ckeditor :editor="editor" v-model="item.option_b" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options" v-if="parseInt(item.question_type) === 0">
								<label for="">Option C</label>
								<ckeditor :editor="editor" v-model="item.option_c" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options" v-if="parseInt(item.question_type) === 0">
								<label for="">Option D</label>
								<ckeditor :editor="editor" v-model="item.option_d" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options" v-if="parseInt(item.question_type) === 0">
								<label for="">Option E</label>
								<ckeditor :editor="editor" v-model="item.option_e" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<label for="">Correct Answer</label>
								<select class="form-select" v-model="item.correct_ans">
									<option :value="initialValue">Select Correct Answer</option>
									<option value="1"> Option A</option>
									<option value="2"> Option B</option>
									<option value="3"> Option C</option>
									<option value="4"> Option D</option>
									<option value="5"> Option E</option>
								</select>
								<label>Correction type</label>
								<select v-model="item.correction_type" class="form-select">
									<option value="0">Text</option>
									<option value="1">Image</option>
								</select>
							</div>
							<div class="col-6 options">
								<label for="">Correction </label>
								<div v-if="parseInt(item.correction_type) === 0">
									<ckeditor :editor="editor" v-model="item.correction" :config="editorConfig"></ckeditor>
								</div>
								<div v-if="parseInt(item.correction_type) === 1">
									<img class="image" id="previewImage" :src="setImage(item.correction_image)" alt="">
									<label class="custom-file-upload">
										<input type="file" @change="handleFileChangeCorrection" :data-id="i" id="profile-image-input" accept="image/*"/>
										Add Correction Image
									</label>
								</div>
							</div>
							<div class="col-6">
								<div>
									<label>Question Image</label>
								</div>
								<img class="image" id="previewImage" :src="setImage(item.question_image)" alt="">
								<label class="custom-file-upload">
									<input type="file" @change="handleFileChange" :data-id="i" id="profile-image-input" accept="image/*"/>
									Add Question Related Image
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="card" v-if="isLoading && !showErrorPageAlert">
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="alert alert-danger" v-if="isLoading && showErrorPageAlert" style="margin-bottom: 0px !important;">
			<h4 style="font-size: 17px !important; font-weight: bold;">{{ errorMessage }}</h4>
			<button class="btn btn-danger" @click="loadTopic" style="margin-top: 7px;">Reload Content</button>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Mathematics from 'ckeditor5-math/src/math';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import { projectService } from '@/services/project.service'
import { fileMgt } from '@/services/fileMgt.service'

export default {
	name: 'CreatePastQuestion',
	props: {
		questionId: Number,
		yearList: [Array, Object],
		courseList: [Array, Object],
		courseId: Number
	},
	setup(props) {
		const fform = reactive({
			year_id: null,
			course_id: props.courseId,
			topic_id: null,
			question_content: [
				{
					id: null,
					question: '',
					option_a: '',
					option_b: '',
					option_c: '',
					option_d: '',
					option_e: '',
					correct_ans: null,
					question_image: '',
					correction_type: 0,
					correction: '',
					correction_image: '',
					question_type: 0
				}
			]
		})
		const rules = computed(() => (
			{
				year_id: { required },
				course_id: { required },
				topic_id: { required },
				question_content: {}
			}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			folder_path: '',
			isLoading: true,
			isProcessing: false,
			showErrorPageAlert: false,
			errorMessage: '',
			loadingMessage: 'Loading Lecture Note',
			topicList: [],
			initialValue: null,
			question: [],
			questionImageList: [],
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Alignment,
				Mathematics,
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'alignment',
					'math'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			optionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'math'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 100,
				allowedContent: true
			},
			isImageChanged: false,
			isCorrectionImageChanged: false
		};
	},
	mounted () {
		this.folder_path = this.$route.params.folder
		this.loadTopic()
	},
	methods: {
		loadTopic () {
			this.isLoading = true
			this.showErrorPageAlert = false
			projectService.fetchTopicFile(`${this.folder_path}/topic.json`, this.fform.course_id)
				.then((response) => {
					this.topicList = []
					this.fform.topic_id = null
					if(parseInt(response.data.message_code) === 200){
						this.topicList = response.data.topic
						this.loadQuestion()
					}else{
						this.isLoading = true
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message_data
					}
				})
				.catch((error) => {
					this.isLoading
					console.log(error)
					this.showErrorPageAlert = true
					this.errorMessage = 'Error Connecting To Server'
				})
		},
		async loadQuestion () {
			const postData = {
				folder: this.folder_path,
				question_id: this.questionId
			}
			fileMgt.fetchSinglePastQuestion(postData)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.question){
							if(parseInt(item.question_id) === parseInt(this.questionId)){
								this.question = item
								this.fform.year_id = this.question.year_id
								this.fform.topic_id = this.question.topic_id
								this.fform.question_content[0].id = item.question_id
								this.fform.question_content[0].question = this.$generalHelper.decodeHtmlEntites(this.question.question)
								this.fform.question_content[0].option_a = this.$generalHelper.decodeHtmlEntites(this.question.option_a)
								this.fform.question_content[0].option_b = this.$generalHelper.decodeHtmlEntites(this.question.option_b)
								this.fform.question_content[0].option_c = this.$generalHelper.decodeHtmlEntites(this.question.option_c)
								this.fform.question_content[0].option_d = this.$generalHelper.decodeHtmlEntites(this.question.option_d)
								this.fform.question_content[0].option_e = this.$generalHelper.decodeHtmlEntites(this.question.option_e)
								this.fform.question_content[0].correct_ans = this.$generalHelper.decodeHtmlEntites(this.question.correct_answer)
								this.fform.question_content[0].correction_type = parseInt(this.question.correction_type)
								this.fform.question_content[0].correction = this.$generalHelper.decodeHtmlEntites(this.question.correction)
								this.fform.question_content[0].correction_image = this.question.correction_image
								this.fform.question_content[0].question_image = this.question.question_image
								this.fform.question_content[0].question_type = this.question.question_type
							}
						}
						this.isLoading = false
						setTimeout(() => {
							eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
						}, 500)
					}else{
						this.isLoading = true
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message_data
					}
				})
				.catch((error) => {
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
		},
		setImage (image) {
			if(image.length > 0){
				const index = image.indexOf('base64')
				if(index !== -1) {
					return image
				}else{
					return `${projectService.apiUrl}/load-project-image/${this.$route.params.folder}/past_question/${image}`
				}
			}
		},
		async handleFileChange (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].question_image = image
				this.isImageChanged = true
			}
		},
		async handleFileChangeCorrection (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].correction_image = image
				this.isCorrectionImageChanged = true
			}
		},
		computeTotalQuestion () {
			return new Promise((resolve) => {
				const newPastQuestion = []
				this.fform.question_content.map((item) => {
					if(item.question.length > 0 && item.option_a.length > 0 && item.option_b.length && item.option_c.length > 0 && item.option_d.length > 0 && item.correct_ans !== null && parseInt(item.question_type) === 0) {
						newPastQuestion.push(`(${ this.fform.course_id })`)
					}else if(item.question.length > 0 && parseInt(item.question_type) === 1){
						newPastQuestion.push(`(${ this.fform.course_id })`)
					}
				})
				resolve(newPastQuestion.length)
			})
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;

			const totalQuestions = await this.computeTotalQuestion()
			if(totalQuestions === 0){
				this.$swal({
					icon: 'error',
					text: 'Please enter atleast a question before submission'
				})
				return
			}

			this.$swal({
				text: `Are you sure that all input are entered correctly`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, update',
				allowOutsideClick: false
			}).then(async (response) => {
				if(response.isConfirmed) {
					this.isProcessing = true
					const postData = {
						folder: this.folder_path,
						question_content: this.fform,
						question_id: this.questionId,
						old_question_image: this.question?.question_image,
						old_correction_image: this.question?.correction_image
					}
					fileMgt.updatePastQuestion(postData)
						.then((response) => {
							this.isProcessing = false
							if(parseInt(response.data.message_code) === 200){
								this.$swal('Past question updated successsfull')
								this.$emit('closeForm')
							}else{
								this.$swal({
									text: response.data.message,
									icon: 'error'
								})
							}
						})
						.catch((error) => {
							this.isProcessing = false
							console.log(error.message)
							this.$swal({
								text: 'Error Connecting To Server',
								icon: 'error'
							})
						})
				}
			})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid #f4f5f8;
	background-color: #f4f5f8;
	margin-right: 20px;
}
.frame{
	height: calc(100vh - 320px);
	overflow-y: scroll;
	padding-bottom: 30px;
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>