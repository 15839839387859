import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class Project{
	apiUrl = baseUrl

	async fetchProject () {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-project`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSchoolProject () {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-school`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async downloadProject (filename) {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/download-project-file/${filename}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createProject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-project`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createSchoolProject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-school`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteProject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-project`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteSchoolProject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-school`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchProjectFile (filename) {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-project-file/${filename}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createCourse (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-course`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateCourse (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-course`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteCourse (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-course`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchTopicFile (filename, course_id) {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-topic-file/${filename}/${course_id}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createTopic (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-topic`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteTopic (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-topic`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-year`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-year`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-year`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

}

const projectService = new Project()
export { projectService }